import React from 'react';
import { graphql } from 'gatsby';
// import ImageGallery from 'react-image-gallery';

import PageHeader from '../components/PageHeader';
import Content from '../components/Content';
import Layout from '../components/Layout';
// import Accordion from '../components/Accordion';
// import BackgroundVideo from '../components/BackgroundVideo';
import Gallery from '../components/Gallery';
// import Popup from '../components/Popup';

// import Gallery1 from '../../static/images/slide/01.jpg';
// import Gallery2 from '../../static/images/slide/02.jpg';
// import Gallery3 from '../../static/images/slide/03.jpg';
// import Gallery4 from '../../static/images/slide/04.jpg';
// import Gallery5 from '../../static/images/slide/05.jpg';
// import Gallery6 from '../../static/images/slide/06.jpg';
// import Gallery7 from '../../static/images/slide/07.jpg';
// import Gallery8 from '../../static/images/slide/08.jpg';
// import Gallery9 from '../../static/images/slide/09.jpg';
// import Gallery10 from '../../static/images/slide/10.jpg';
// import Gallery11 from '../../static/images/slide/11.jpg';

// const photos = [
//   { original: Gallery1, thumbnail: Gallery1 },
//   { original: Gallery2, thumbnail: Gallery2 },
//   { original: Gallery3, thumbnail: Gallery3 },
//   { original: Gallery4, thumbnail: Gallery4 },
//   { original: Gallery5, thumbnail: Gallery5 },
//   { original: Gallery6, thumbnail: Gallery6 },
//   { original: Gallery7, thumbnail: Gallery7 },
//   { original: Gallery8, thumbnail: Gallery8 },
//   { original: Gallery9, thumbnail: Gallery9 },
//   // { original: Gallery10, thumbnail: Gallery10 },
//   // { original: Gallery11, thumbnail: Gallery11 },
// ];

// Export Template for use in CMS preview
export const ComponentsPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  section1,
  // section2,
  // video,
  // videoPoster,
  // videoTitle,
  // accordion,
  // body,
  gallery,
}) => (
  <main>
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
    <section className="section">
      <div className="container">
        <Content source={section1} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2>Gallery</h2>
        {/* <div
          style={{
            margin: '0 auto',
            maxWidth: '65em',
            width: 'calc(100% - 6em)',
          }}
        >
          <div>
            <ImageGallery items={photos} />
          </div>
        </div> */}
        <Gallery images={gallery} />
      </div>
    </section>

    {/* <section className="section">
      <div className="container">
        <Content source={section2} />
      </div>
    </section> */}

    {/* <section className="BackgroundVideo-section section">
      <BackgroundVideo poster={videoPoster} videoTitle={videoTitle}>
        {video && <source src={video} type="video/mp4" />}
      </BackgroundVideo>
    </section> */}

    {/* <section className="section">
      <div className="container">
        <Accordion items={accordion} />
      </div>
    </section> */}

    {/* <section className="section">
      <div className="container">
        <Popup>
          <Content source={section1} />
        </Popup>
      </div>
    </section> */}
  </main>
);

const ComponentsPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ComponentsPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
);

export default ComponentsPage;

export const pageQuery = graphql`
  query ComponentsPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        section1
        section2
        video
        videoPoster
        videoTitle
        accordion {
          title
          description
        }
      }
    }
  }
`;
