import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { PhotoSwipe } from 'react-photoswipe';
// import _kebabCase from 'lodash/kebabCase';
// import Image from './Image';
import ImageGallery from 'react-image-gallery';

import './Gallery.css';
import 'react-photoswipe/lib/photoswipe.css';

export const query = graphql`
  fragment Gallery on MarkdownRemark {
    frontmatter {
      gallery {
        alt
        image
        title
      }
    }
  }
`;

export default class Gallery extends Component {
  state = {
    loaded: false,
    isOpen: false,
    sliderImages: [],
    index: 0,
  }

  isOpen(isOpen, index) {
    if (typeof index === 'undefined') index = 0;
    this.setState({ isOpen, index });
  }

  handleKeyDown = (ev) => {
    if (ev.keyCode === 13 && !this.state.isOpen) {
      // enter to open
      this.isOpen(true, this.state.index);
    }
  }

  getImageInfo = (img, index) => fetch(`${img.image}-/json/`)
    .then((res) => res.json())
    .then(
      (result) => {
        const newImagesArr = [...this.state.sliderImages];
        newImagesArr[index] = {
          src: img.image,
          title: img.title,
          w: result.width,
          h: result.height,
        };
        this.setState({
          sliderImages: newImagesArr,
        });
        return true;
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        return false;
      },
    )

  componentDidMount() {
    const { images } = this.props;
    const maxCount = images.length;
    let loopCount = 1;

    for (const i in images) {
      if (this.getImageInfo(images[i], i)) {
        this.setState({ loaded: loopCount === maxCount });
        loopCount += 1;
      }
    }
  }

  render() {
    const { images } = this.props;
    const x = images.map((i) => ({ original: i.image, thumbnail: i.image }));
    return (
      <>
        {x && images.length > 0 && (
          <div className="Gallery">
            <div
              style={{
                margin: '0 auto',
                maxWidth: '65em',
                width: 'calc(100% - 6em)',
              }}
            >
              <div>
                <ImageGallery items={x} />
              </div>
            </div>

            {/* {images.map((image, index) => (
              <div
                className="Gallery--Item"
                key={`${_kebabCase(image.alt)}-${index}`}
                onClick={() => this.isOpen(true, index)}
                onKeyDown={this.handleKeyDown}
                tabIndex={0}
                aria-label="Toggle Gallery"
                role="button"
              >
                <div>
                  <Image
                    resolutions="small"
                    src={image.image}
                    alt={image.alt}
                  />
                </div>
                {image.title && <figcaption>{image.title}</figcaption>}
              </div>
            ))} */}
          </div>
        )}
        {this.state.loaded && this.state.sliderImages.length > 0 && (
          <PhotoSwipe
            isOpen={this.state.isOpen}
            items={this.state.sliderImages}
            options={{
              index: this.state.index,
              history: false,
            }}
            onClose={() => this.isOpen(false)}
          />
        )}
      </>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.array.isRequired,
};
